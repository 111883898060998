import React from 'react'
import styled from "styled-components"


const MapResponsive = styled.div`
    margin: 0;
    overflow:hidden;
    position:relative;
    height: 0;
    iframe{
    left:0;
    top:0;
    height:100%;
    width:100%;
    position:absolute;
        }
`
export default function index() {
  return (
    <MapResponsive>
     <iframe title="konya mimar konum" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12597.368841099833!2d32.4822277!3d37.875679!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa818d9c7b17d86e2!2sFurkan%20Acar%3A%20Konya%20Mimar%20%7C%20Mimarl%C4%B1k%20%7C%20Gayrimenkul%20De%C4%9Ferleme%20%7C%20Enerji%20Kimlik%20Belgesi!5e0!3m2!1str!2str!4v1613595316901!5m2!1str!2str" width={500} height={600} frameBorder={0} style={{border: 0}} allowFullScreen aria-hidden="false"  />

    </MapResponsive>
  )
}
