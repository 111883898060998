import React from "react"
import SEO from '../components/PageSEO';
import Layout from '../containers/layout/layout'
import ContactFormSection from '../containers/contact/contact-form'
import ContactInfoSection from '../containers/contact/contact-info'

export default () => (
    <Layout headerStyle="darkHeader">
        <SEO title="Furkan Acar Mimarlık | İletişim | Adres | Telefon "
            description="Yeni bir Mimar veya size özel projenize destek verecek bir Mimarlık Ofisi mi arıyorsunuz? Furkan Acar ile buradan iletişime geçin." />
        <ContactFormSection />
        <ContactInfoSection />
    </Layout>
)
